.password-generator {
  font-size: 1.4rem;
}

.password-generator header h1 {
  font-size: 2.5em;
  font-weight: bold;
  margin: 0;
  text-align: center;
}

@media (max-width: 550px) {
  .password-generator header h1 {
    font-size: 1.3em;
  }
}

.password-generator header {
  margin-bottom: 2em;
}

.password-generator .layout-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: auto;
}

.password-generator .layout-container > div {
  -ms-flex-preferred-size: 10em;
  flex-basis: 10em;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
  margin: 0.5em 2em;
  max-width: 20em;
}

.password-generator .layout-container .result-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
