@import "../../variables.css";

#password-gen-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: auto;
}

#password-gen-form .form-paper .form-options {
  padding: 5% 10%;
}

#password-gen-form .form-header {
  padding: 0.8em 1em;
  background: var(--material-ui-primary-blue);
  border-radius: 4px 4px 0 0;
  text-align: center;
}

#password-gen-form .form-element {
  margin: auto;
}

#password-gen-form .form-buttons {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

#password-gen-form .form-buttons button:first-of-type {
  border-radius: 0 0 0 4px;
  border-left: 0;
  color: var(--material-ui-secondary-pink);
}

#password-gen-form .form-buttons button:last-of-type {
  border-radius: 0 0 4px 0;
  border-right: 0;
  color: var(--material-ui-primary-blue);
}
