@import "../../variables.css";

@media (max-width: 550px) {
  .result-paper .password {
    font-size: 0.8em;
  }
}

@media (max-width: 450px) {
  .result-paper .password {
    font-size: 0.75em;
  }
}

@media (max-width: 400px) {
  .result-paper .password {
    font-size: 0.7em;
  }
}

@media (max-width: 300px) {
  .result-paper .password {
    font-size: 0.6em;
  }
}

.result-paper {
  min-width: 100%;
  text-align: center;
}

.result-paper .password {
  padding: 1em;
}

.result-paper .result-buttons {
  border-radius: 0 0 4px 4px;
}

.result-paper .result-buttons button:first-of-type {
  border-radius: 0 0 0 4px;
  border-left: 0;
  color: var(--material-ui-secondary-pink);
}

.result-paper .result-buttons button:last-of-type {
  border-radius: 0 0 4px 0;
  border-right: 0;
  color: var(--material-ui-primary-blue);
}

.copied-text {
  text-align: center;
}
